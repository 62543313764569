.seller-checkbox {
    height: 20px;
    width: 20px;
  }
  
  .seller-label {
    font-size: 15px;
  
    margin: 0px;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .fw-bold {
    font-weight: 700 !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .seller-checkbox {
    height: 20px;
    width: 20px;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .seller-checkbox {
    height: 20px;
    width: 20px;
  }
  
  .ml-1 {
    margin-left: 1rem;
  }
  
  .ml-2 {
    margin-left: 1rem;
  }
  
  .border-primary {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-color: #000000 !important;
    /* boredr */
  }
  
  .seller-btn {
    background-color: #01dea5;
    border: none;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
  
  .dialog-box .modal-dialog {
    width: 500px !important;
  }
  
  .auth-user input {
    width: 100%;
    height: 40px;
    border: 3px solid #2bccec80;
  }
  
  .auth-user button {
    width: 130px;
    padding: 8px;
    font-weight: 700;
    margin-top: 10px;
    border: none;
    background-color: #2bccec80;
    border-radius: 5px;
  }
  
  .details .details-containt {
    width: 600px;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 30px;
  }
  
  .border-primary {
    position: relative;
  }
  
  .border-primary .form-logo {
    position: absolute;
    top: 10px;
  }
  
  .border-primary .form-logo-2 {}
  
  .border-primary .form-logo-2 img {
    width: 13%;
    position: absolute;
    top: 6px;
    right: 10px;
  }
  
  /* .colorChange .box {
    width: 20px;
    height: 20px;
  } */
  
  .conatct-seller {
    margin: auto;
    width: 900px !important;
  }
  
  .Blood-Group {
    display: flex;
  }
  
  .Category-1 {
    display: flex;
  }
  
  .Marital-Status {
    display: flex;
  }
  
  .Relationship {
    display: flex;
  }
  
  .Educational {
    display: flex;
  }
  
  .Village {
    display: flex;
  }
  
  .Correspondence {
    display: flex;
  }
  
  .Permanent {
    display: flex;
  }
  
  .Village-sec {
    display: flex;
  }
  
  .Disability {
    display: flex;
  }
  
  .Area-of-Disability {
    display: flex;
  }
  
  .Gender {
    display: flex;
  }
  
  .have-Disability {
    display: flex;
  }
  
  @media (max-width: 425px) {
    .disc {
      padding: 0px;
      list-style: none;
    }
  
    .Blood-Group {
      display: block !important;
      padding: 0px;
    }
  
    .Category-1 {
      display: block !important;
      padding: 0px;
    }
  
    .Marital-Status {
      display: block !important;
      padding: 0px;
    }
  
    .Relationship {
      display: block !important;
    }
  
    .Educational {
      display: block !important;
    }
  
    .display {
      display: block !important;
    }
  
    .Correspondence textarea {
      display: block !important;
    }
  
    .Permanent {
      display: block !important;
    }
  
    .Permanent textarea {
      width: 100% !important;
    }
  
    .Village-sec {
      display: block;
    }
  
    .Village-sec input {
      width: 100% !important;
    }
  
    .Disability {
      display: block !important;
    }
  
    .Area-of-Disability {
      display: block !important;
    }
  
    .Identity-proof {
      display: block !important;
    }
  
    .border-primary .form-logo {
      display: none;
    }
  
    .border-primary .form-logo-2 img {
      top: 112px;
    }
  
    .Gender {
      display: block !important;
    }
  
    .disc-2 li .pl-2 {
      margin-bottom: 8px !important;
      width: 300px;
    }
  
    .have-Disability {
      display: block !important;
    }
  }

  .login-section {
    width: 400px;
    margin: auto;
  }