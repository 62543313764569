.navTop-box {
    position: sticky;
    top: 0;
    z-index: 55;
    width: 100%;
    box-shadow: 1px 1px 4px 3px lightgray;
}

.toplist-div {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-around;
    background-color: #6d288c;
}

.fontsizebtn .p-tag-btn1,
.p-tag-btn2,
.p-tag-btn3 {
    background-color: white;
    margin: 0 5px;
    border: 1px solid white;
    border-radius: 4px;
    font-weight: bold;
    color: grey;
    cursor: pointer;
}

.fontsizebtn .p-tag-btn1 {
    width: 20px;
    font-size: 14px;
    height: 20px;
}

.fontsizebtn .p-tag-btn2 {
    width: 25px;
    font-size: 16px;
    height: 25px;
}

.fontsizebtn .p-tag-btn3 {
    width: 30px;
    font-size: 18px;
    height: 30px;
}

.colorchangebtn .ptag-color1,
.ptag-color2,
.ptag-color3 {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 0 5px;
    border: 1px solid white;
    text-align: center;
    cursor: pointer;
}

.colorchangebtn .ptag-color1 {
    background-color: #6d288c;
    font-size: 15px;
}

.colorchangebtn .ptag-color2 {
    background-color: #009115;
    font-size: 17px;
}

.colorchangebtn .ptag-color3 {
    background-color: #C08A00;
    font-size: 19px;
}

.navsec-box {
    display: flex;
    margin: 8px 20px 5px 20px;
    justify-content: space-between;
}

.logo-img-div {
    margin-right: 10px;
    display: flex;
    padding: 16px 0 8px 0;
    margin-left: 16px;
}

.logo-img-div .logo-imgg {
    height: 80px;
    border-radius: 8px;
    object-fit: contain;
}

.logo-img-div h6 {
    color: black;
    width: 250px;
    text-align: center;
    font-weight: bold;
    margin: 10px 0 0 20px;
    font-size: 20px;
}

.imgbox img {
    width: 120px;
    height: 80px;
    margin: 20px 30px;
}

.inputt-div {
    border: 1px solid lightgrey;
    border-radius: 20px;
    height: 40px;
    margin-top: 35px;
    background-color: white;
}

.inputt-div .inputt-area {
    background-color: white;
    width: 150px;
    margin: 3px 16px;
}

.inputt-div .btnn {
    border-radius: 0 20px 20px 0;
    background-color: #6d288c;
    margin-left: 16px;
}

.logo-img-div h5 {
    color: black;
    width: 200px;
    text-align: center;
    margin: 8px 0 0 10px;
    font-size: 20px;
    line-height: 30px;
}

.logo-img-div h5 b {
    color: black;
    font-size: 22px;
}

.navthird-box {
    position: sticky;
    top: 73px;
    z-index: 55;
    border-top: 1px solid white;
}


.nav-list {
    margin: 5px 0;
    align-items: center;
}

.nav-list a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
}

.nav-list a:hover {
    background-color: #c89d00;
    padding: 5px 10px;
    border-radius: 6px;
}

.nav-list {
    position: relative;
}

.purpal {
    background-color: #6d288c !important;
}

.geen {
    background-color: #009115 !important;
}

.orange {
    background-color: #C08A00 !important;
}

.navres-list {
    font-size: 18px;
    margin-top: 15px;
    align-items: center;
}

.navres-list a {
    text-decoration: none;
    color: crimson;
    padding: 3px 20px;
    font-size: 18px;
}

@media screen and (max-width:600px) {
    .toplist-div {
        display: block !important;
        margin: 20px;
        background-color: #6d288c;
    }

    .navthird-box {
        display: none !important;
    }
    /* .imgbox {
        display: none !important;
    } */
    .navsec-box {
        display: block;
        justify-content: center;
    }
    .imgbox{
        display: none;
    }
    
    .inputt-div .inputt-area{
        width: 350px;
    }
}