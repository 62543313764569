.h3-tagg{
    margin: 30px 90px 10px 90px;
}
.imgesdiv{
    width: 600px;
}
.imgesdiv .img1{
    width: 400px;
    height: 450px;
    object-fit: cover;
    margin: 50px 0 10px 100px;
    border-radius: 10px;
}
.imgesdiv .img2{
    width: 400px;
    height: 350px;
    object-fit: cover;
    margin: 10px 0 10px 100px;
    border-radius: 10px;
}
.sideimg-div{
    border: 6px solid white;
    border-radius: 10px;
    margin: 30px 40px;
}
.sideimg-div img{
    border-radius: 6px 0 0 6px;
    object-fit: cover;
    height: 250px;
    width: 170px;
}
.sideimg-div .content-div{
    padding: 20px 4px 4px 40px;
    width: 330px;
    border-radius: 0 6px 6px 0;
    color: white;
}
.purpal {
    background-color: #6d288c !important;
}

.geen {
    background-color: #009115 !important;
}

.orange {
    background-color: #C08A00 !important;
}
@media screen and (max-width:600px) {
    .imgesdiv .img1{
        width: 370px;
        height: 250px;
        object-fit: contain;
        border-radius: 6px;
    }
    .imgesdiv,.img2{
        width: 300px;
        height: 250px;
        object-fit: contain;
        border-radius: 6px;
    }
    .sideimg-div{
        margin: 10px 35px;
    }
    .sideimg-div img{
        border-radius: 6px 0 0 6px;
        object-fit: cover;
        height: 150px;
        width: 100px;
    }
    .sideimg-div .content-div{
        padding: 20px 4px 4px 40px;
        width: 250px;
        border-radius: 0 6px 6px 0;
        color: white;
    }
    .h3-tagg{
        margin: 30px 10px 10px 10px;
    }
    .rightsidebox{
        margin-top: 420px !important;
    }
}